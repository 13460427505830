<template lang="">
  <div>
    <!-- Start Filter -->
    <b-card v-if="showFilter">
      <h1>Filter</h1>
      <b-row>
        <b-col cols="8">
          <b-form-group
            label-cols-md="3"
            label="Periode"
          >
            <b-input-group>
              <flat-pickr
                v-model="date_start"
                class="form-control"
                placeholder="Tanggal Awal"
              />
              <b-input-group-append is-text>
                To
              </b-input-group-append>
              <flat-pickr
                v-model="date_end"
                class="form-control"
                placeholder="Tanggal Akhir"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            class="mr-1"
            size="sm"
            variant="primary"
            type="button"
            @click.prevent="show"
          >Show</b-button>
          <b-button
            class="mr-1"
            size="sm"
            variant="danger"
            type="button"
            @click.prevent="reset"
          >Reset</b-button>
        </b-col>
      </b-row>
    </b-card>
    <!-- End Of Filter -->
    <b-card>
      <basetable
        ref="basetable"
        :dataurl="dataurl+(userType=='vendor'?'?vendor_id='+user.id:'')"
        :fields="fields"
        :baseroute="baseroute"
        :title="title"
        :filter="filter"
        :is-edit="false"
        :is-delete="false"
      >
        <template v-slot:buttonaddon>
          <!-- <b-button
            class="mr-1"
            size="sm"
            variant="success"
            @click="showFilter =!showFilter"
          >
            <feather-icon icon="FilterIcon" />
            Filter
          </b-button> -->
          <!-- <b-button
            class="mr-1"
            size="sm"
            variant="warning"
          >
            Export
          </b-button> -->
        </template>
        <template #cell(status)="data">
          <span
            v-if="data.item.status == 'created'"
            class="badge bg-warning"
          >Pengajuan</span>
          <span
            v-else-if="data.item.status == 'approved'"
            class="badge bg-primary"
          >Disetujui</span>
          <span
            v-else-if="data.item.status == 'rejected'"
            class="badge bg-danger"
          >Dibatalkan</span>
          <span
            v-else
            class="badge bg-info"
          >Status tidak di ketahui</span>
        </template>
      </basetable>
    </b-card>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTable.vue'
import { BCard,BButton,BFormGroup,BFormSelect,BRow,BCol, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'

export default {
  components: {
    basetable,
    BCard,
    BButton,
    BFormGroup,
    BFormSelect,
    BRow,
    BCol,
    flatPickr,
    BInputGroup,
    BInputGroupAppend
  },
  data() {
    return {
      userType:null,
      user:null,
      dataurl:"/claim",
      baseroute:"v-vehicle-claim",
      title:"Daftar Klaim Kendaraan",
      fields: [
        { key: 'number', label: 'Nomor Klaim', sortable: true },
        { key: 'spb.number', label: 'Nomor SPB', sortable: true },
        { key: 'date', label: 'Tgl Pengajuan', sortable: true },
        { key: 'vehicle.number', label: 'Nopol Kendaraan', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
      ],
      showFilter : false,
      filter :[],
      active:null,
      date_start:"",
      date_end:""
    }
  },
  created(){
    if(this.$store.state.profile.info){
      var info = this.$store.state.profile.info
      this.userType = info.contact_type.split('\\').pop().toLowerCase()
      this.user = this.$store.state.profile.info.contact
    }
    if(this.$route.meta.baseroute)
      this.baseroute = this.$route.meta.baseroute
  },
  methods:{
    show(){
      // this.filter['filter[active]']=this.active
      if(this.date_start != "" && this.date_end != "")
        this.filter['between[request_date]']=this.date_start+","+this.date_end

      this.$refs.basetable.fetchData()
    },
    reset(){
      this.date_start = ""
      this.date_end = ""
      this.filter['between[request_date]'] = null
      this.show()
    }
  }
}
</script>
<style lang="">

</style>
